import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import style from "./avisos.module.scss"
import imgPage from "../images/headers/Logos_Ale.svg"
import Header from "../components/header";
import Img from "gatsby-image"

export default ({data}) => {

    return (
        <Layout footer='default'>

            <SEO title="Derechos Arco"/>
            <Header LogoPage={imgPage}/>

            <div className={style.avisosPortada}>
                <Img style={{maxHeight: '400px'}} fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <div className={style.avisosContent}>
                <h2>Derechos arco</h2>
                <p>Solicitud de Derechos  ARCO<br/>
                    Llene el formato con los campos aquí descritos y envié su solicitud a la siguiente cuenta:  <a href="mailto:atenciondatos@aerolineasejecutivas.com">atenciondatos@aerolineasejecutivas.com</a><br/><br/>
                    Descargue la solicitud <a href={data.arco.publicURL} download>aquí</a>.
                </p>
            </div>
        </Layout>
    )

}

export const query = graphql`
    query ImagesDerechos {
    portada: file(relativePath: {eq: "avisos/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    arco: file(relativePath: {regex: "/ARCO_ES/"}) {
            publicURL
      }
}`

